<template>
  <div>
    <div class="newsList" v-if="list.length > 0">
      <div
        class="link"
        v-for="(item, index) in list"
        :key="index"
        @click="toDetails(item, index)"
      >
        <a href="javascript:void(0)">
          <span>{{ item.title }}</span>
          <span>{{ item.createTime }}</span>
        </a>
      </div>
    </div>
    <el-pagination
      :hide-on-single-page="total == 0"
      style="text-align: right; margin-top: 20px"
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
    <div class="not-data" v-if="list.length == 0">暂无数据</div>
  </div>
</template>

<script>
import { pagination } from "element-ui";
export default {
  components: {
    "el-pagination": pagination,
  },
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: null,
    },
    pageNo: Number,
    pageSize: Number,
    total: Number,
    keyword: String,
    current: Number,
    detailsType: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("paging", val);
    },
    toDetails(item, index) {
      if (this.detailsType == 1) {
        sessionStorage.setItem("classification", item.bizCategory.id);
        sessionStorage.setItem("articleId", item.id);
        this.$router.push({ path: "/third" });
      } else {
        this.$emit("toDetails", item.id)
      }
    },
  },
};
</script>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c01515;
  color: #fff;
}
</style>
<style lang="scss" scoped>
@import "./../../assets/style/public.scss";
.not-data {
  min-height: 472px;
  text-align: center;
  font-size: 16px;
  color: #888888;
  margin-top: 30px;
}
.newsList {
  @extend %clearfix;
  min-height: 472px;
  .link {
    width: 100%;
    height: 32px;
    float: left;
    margin-top: 20px;
    a {
      font-size: 16px;
      color: #222222;
      line-height: 32px;
      display: block;
      span {
        &:first-child {
          width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          float: left;
        }
        &:last-child {
          float: right;
        }
      }
      &:hover {
        // text-decoration: underline;
        color: #c01515;
      }
    }
  }
}
</style>
