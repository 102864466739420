<template>
  <div class="leftMenu">
    <template v-if="showList">
      <div class="title">{{ title }}</div>
      <div
        class="item"
        v-for="(item, idx) in menus"
        :key="idx"
        :class="{ selected: selectedIndex == idx }"
        @click="onRouterPush(idx, item)"
      >
        <img
          v-if="selectedIndex == idx"
          class="icon"
          :src="item.iconSeleted"
          alt=""
        />
        <img v-else class="icon" :src="item.icon" alt="" />
        <span>{{ item.name }}</span>
      </div>
    </template>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    menus: Array,
    showList: {
      type: Boolean,
      default: true,
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    title: String,
  },
  data() {
    return {
      // selectedIndex: 0,
    };
  },
  methods: {
    onRouterPush(idx, item) {
      this.$emit("monitorMenus", idx, item);
    },
  },
};
</script>

<style lang="scss" scoped>
.leftMenu {
  width: 100%;
  min-height: 472px;
  background: #f9f9f9;
  float: left;
  .title {
    height: 52px;
    line-height: 52px;
    font-weight: bold;
    font-size: 18px;
    padding-left: 80px;
  }
  .item {
    width: 232px;
    padding-left: 48px;
    line-height: 52px;
    float: left;
    cursor: pointer;
    .icon {
      width: 19px;
      position: relative;
      top: 4px;
      margin-right: 17px;
    }
    &:hover {
      background: rgba(218, 64, 64, 0.3);
    }
    &.selected {
      background: #c01515;
      color: #fff;
    }
  }
}
</style>