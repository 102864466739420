<template>
  <div class="search_page">
    <Crumbs :breadList="crumbs" />
    <div class="main">
      <div class="l_box">
        <!-- <LeftRollMenu :arr="leftList"></LeftRollMenu> -->
        <LeftMenu :title="'搜索结果'" :menus="menus" />
      </div>
      <div class="r_box">
        <!-- <div class="title">查询结果</div> -->
        <NewsList
          :list="searchArr"
          :pageNo="pageNo"
          :pageSize="pageSize"
          :total="total"
          :current="current"
          :keyword="keyword"
          @paging="onPaging"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LeftRollMenu from "@/components/leftMenu/roll-index";
import Crumbs from "@/components/crumbs/index";
import NewsList from "@/components/newsList/index";
import { mapState } from "vuex";
import LeftMenu from "@/components/leftMenu/index";
export default {
  components: {
    Crumbs,
    NewsList,
    // LeftRollMenu,
    LeftMenu
  },
  computed: {
    ...mapState({
      searchArr: (state) => state.search.searchArr, //使用ES6的箭头函数来给count赋值
      total: (state) => state.search.total,
      keyword: (state) => state.search.keyword,
      pageSize: (state) => state.search.pageSize,
      current: (state) => state.search.current,
    }),
  },
  data() {
    return {
      pageNo: 1,
      leftList: [],
      crumbs: [
        {
          name: "首页",
          link: "/",
        },
        {
          name: "查询结果",
          link: "",
        },
      ],
      menus:[
        {
          name: "查询结果"
        }
      ]
    };
  },
  created() {
    this.getRollList();
  },
  methods: {
    onPaging(value) {
      this.pageNo = value;
      this.$store.dispatch("search/getSearchData", {
        keyword: this.keyword,
        pageNo: this.pageNo,
      });
    },
    // 查询滚动通知
    async getRollList() {
      let _this = this;
      let res = await this.$api.home.noticeList();
      if (res.status == 200) {
        _this.leftList = res.data.records;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search_page {
  .title {
    font-size: 18px;
    letter-spacing: 10px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdfe6;
  }
}
</style>
