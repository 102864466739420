<!-- 滚动菜单 -->
<template>
  <div class="roll-menu">
    <div id="meus" class="content">
      <ul ref="rolUll" @mouseout="onStart" @mouseover="onStop">
        <li v-for="(item, index) in arr" :key="index">
          <a href="javascript:void(0)" @click="toPage(index)">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 50,
      pieces: 3,
      syTimer: null,
      lisTotalHeight: 0,
      resetHeight: 0,
      mapTop: 0,
    };
  },
  props: {
    arr: Array,
  },
  watch: {
    arr: {
      handler: function (val) {
        this.$nextTick(function () {
          this.init();
        });
      },
    },
  },
  beforeDestroy() {
    this.onStop();
  },
  methods: {
    init() {
      if (this.arr.length > this.pieces && this.$refs.rolUll) {
        let _this = this;
        let lis = this.$refs.rolUll.getElementsByTagName("li");
        for (var i = 0; i < lis.length; i++) {
          _this.lisTotalHeight += lis[i].offsetHeight;
        }
        for (var j = 0; j < lis.length - 2; j++) {
          _this.resetHeight += lis[j].offsetHeight;
        }
        this.$refs.rolUll.style.height = _this.lisTotalHeight + "px";
        _this.syTimer = setInterval(function () {
          _this.mapTop -= 1;
          if (_this.mapTop <= -_this.resetHeight) {
            _this.mapTop = 0;
          }
          _this.$refs.rolUll.style.marginTop = _this.mapTop + "px";
        }, _this.num);
      }
    },
    onStart() {
      let _this = this;
      clearInterval(this.syTimer);
      _this.syTimer = setInterval(function () {
        _this.mapTop -= 1;
        if (_this.mapTop <= -_this.resetHeight) {
          _this.mapTop = 0;
        }
        _this.$refs.rolUll.style.marginTop = _this.mapTop + "px";
      }, _this.num);
    },
    onStop() {
      clearInterval(this.syTimer);
    },
    toPage(index) {
      this.$emit("onCakkback", null, index);
    },
  },
};
</script>
<style lang="scss" scoped>
.roll-menu {
  width: 100%;
  min-height: 472px;
  background: #f9f9f9;
  .content {
    width: 100%;
    height: 253px;
    border-radius: 1px;
    overflow: hidden;
    ul {
      padding: 16px 30px;
      li {
        padding-bottom: 23px;
        a {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 216px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 17px;
          // margin-right: 41px;
          display: inline-block;
          &:hover {
            color: #c01515;
          }
        }
      }
    }
  }
}
</style>